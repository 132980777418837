import React from 'react'
import { FaFacebookF, FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="footer spad">
    <div className="container">
        <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="footer__about">
                    <div className="footer__about__logo">
                        <a href="/"><img src={`${process.env.REACT_APP_STORAGE_URL}img/logo.png`} alt /></a>
                    </div>
                    <ul>
                        <li>Address: Kec. Jepara, Kabupaten Jepara, Jawa Tengah</li>
                        <li>Phone: +62 11.188.888</li>
                        <li>Email: hello@toora.com</li>
                    </ul>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-1">
                <div className="footer__widget">
                    <h6>Useful Links</h6>
                    <ul>
                        <li><a href="#">About Us</a></li>
                        <li><a href="#">About Our Shop</a></li>
                        <li><a href="#">Secure Shopping</a></li>
                        <li><a href="#">Delivery infomation</a></li>
                        <li><a href="#">Privacy Policy</a></li>
                        <li><a href="#">Our Sitemap</a></li>
                    </ul>
                    <ul>
                        <li><a href="#">Who We Are</a></li>
                        <li><a href="#">Our Services</a></li>
                        <li><a href="#">Projects</a></li>
                        <li><a href="#">Contact</a></li>
                        <li><a href="#">Innovation</a></li>
                        <li><a href="#">Testimonials</a></li>
                    </ul>
                </div>
            </div>
            <div className="col-lg-4 col-md-12">
                <div className="footer__widget">
                    <h6>Join Our Newsletter Now</h6>
                    <p>Get E-mail updates about our latest shop and special offers.</p>
                    <form action="#">
                        <input type="text" placeholder="Enter your mail" />
                        <button type="submit" className="site-btn">Subscribe</button>
                    </form>
                    <div className="footer__widget__social">
                        <a href="#"><i><FaFacebookF /></i></a>
                        <a href="#"><i><FaInstagram /></i></a>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-12">
                <div className="footer__copyright">
                    <div className="footer__copyright__text"><p>
                        Copyright © All rights reserved
                        </p>
                    </div>
                    <div className="footer__copyright__payment"><img src={`${process.env.REACT_APP_STORAGE_URL}img/payment-item.png`} alt /></div>
                </div>
            </div>
        </div>
    </div>
</footer>
  )
}

export default Footer