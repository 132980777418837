import React, { useEffect, useState } from 'react'
import { FaChevronDown, FaBars, FaChevronLeft, FaChevronRight, FaComment, FaCalendar } from "react-icons/fa";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation, Autoplay } from "swiper";
import { Link } from 'react-router-dom';
import HttpService from '../services/HttpService';
import { NumberFormat } from '../utils/NumberFormat';
import ProductThumbnail from '../components/molecules/ProductThumbnail';
import { titleCase } from '../utils/WordCase';
import ProductHero from '../components/molecules/ProductHero';

const LandingPage = () => {

    const [featuredProducts, setFeaturedProducts] = useState(null);
    const [categories, setCategories] = useState(null);
    const [heroData, setHeroData] = useState(null);
    useEffect(() => {
        getData();
        getCategoriesData();
        getHeroData();
    }, []);

    const getData = async () => {
        const http = new HttpService();
        const url = `product`;
        const dataFetch = await http
            .getDataClient(url)
            .then((data) => {
                return data;
            })
            .catch((error) => {
                return error;
            });
        console.log(dataFetch)
        if (dataFetch.success) {
            setFeaturedProducts(dataFetch.data);
        } else {
            setFeaturedProducts(null);
        }
    };
    const getCategoriesData = async () => {
        const http = new HttpService();
        const url = `category`;
        const dataFetch = await http
            .getDataClient(url)
            .then((data) => {
                return data;
            })
            .catch((error) => {
                return error;
            });
        console.log(dataFetch)
        if (dataFetch.success) {
            setCategories(dataFetch.data);
        } else {
            setCategories(null);
        }
    };
    const getHeroData = async () => {
        const url = 'product?hero=true'
        const http = new HttpService();
        const dataFetch = await http
            .getDataClient(url)
            .then((data) => {
                return data;
            })
            .catch((error) => {
                return error;
            });
        if (dataFetch.success) {
            setHeroData(dataFetch.data)
        } else {
            setHeroData(null);
        }
    };

    return (
        <>
            {/* <div id="preloder">
                <div className="loader" />
            </div> */}

            <ProductHero data={heroData}/>

            <section className="categories">
                <div className="container">
                    <div className="row">
                        {categories &&
                            <div className='w-100 position-relative'>
                                <div className='swiper__nav'>
                                    <div className='swiper__button__product swiper__prev'><FaChevronLeft /></div>
                                    <div className='swiper__button__product swiper__next'><FaChevronRight /></div>
                                </div>
                                <Swiper
                                    className="categories__slider"
                                    slidesPerView={1}
                                    spaceBetween={5}
                                    autoplay={true}
                                    breakpoints={{
                                        640: {
                                            slidesPerView: 2,
                                            spaceBetween: 5,
                                        },
                                        1024: {
                                            slidesPerView: 4,
                                            spaceBetween: 10,
                                        },
                                    }}
                                    // navigation={true}
                                    navigation={{
                                        prevEl: '.swiper__button__product.swiper__prev',
                                        nextEl: '.swiper__button__product.swiper__next',
                                    }}
                                    loop={true}
                                    modules={[Navigation, Autoplay]}
                                >
                                    {categories.map(({ name, slug,thumbnail_img }, i) => (
                                        <SwiperSlide key={i}>
                                            <div className="col-lg-3">
                                                <div className="categories__item set-bg" data-setbg="img/categories/cat-1.jpg" style={{ backgroundImage: `url("${thumbnail_img}")` }}>
                                                    <h5><a href={slug}>{name}</a></h5>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        }
                    </div>
                </div>
            </section>

            <section className="featured spad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title">
                                <h2>Featured Product</h2>
                            </div>
                            <div className="featured__controls">
                                <ul>
                                    <li className="active" data-filter="*">All</li>
                                    {categories && categories.slice(0, 5).map(({ name, slug,thumbnail_img }, i) => (
                                        <li data-filter={slug} key={i}>{titleCase(name)}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row featured__filter">
                        {(featuredProducts && featuredProducts.data) && featuredProducts.data.map((product, i) => (
                            <div className='col-lg-3 col-md-4 col-sm-6 mb-4' key={i}><ProductThumbnail product={product} /></div>
                        ))}
                    </div>
                </div>
            </section>

            <div className="banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="banner__pic">
                                <img src={`${process.env.REACT_APP_STORAGE_URL}img/banner/banner-1.jpg`} alt />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="banner__pic">
                                <img src={`${process.env.REACT_APP_STORAGE_URL}img/banner/banner-2.jpg`} alt />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <section className="latest-product spad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="latest-product__text">
                                <h4>Latest Products</h4>

                                <Swiper
                                    className="latest-product__slider"
                                    slidesPerView={1}
                                    // navigation={true}
                                    // navigation={{
                                    //     prevEl: '.swiper__button__product.swiper__prev',
                                    //     nextEl: '.swiper__button__product.swiper__next',
                                    // }}
                                    loop={true}
                                    modules={[Navigation]}
                                >

                                    <SwiperSlide>
                                        <div className="latest-prdouct__slider__item">
                                            <a href="#" className="latest-product__item">
                                                <div className="latest-product__item__pic">
                                                    <img src={`${process.env.REACT_APP_STORAGE_URL}img/latest-product/lp-1.jpg`} alt />
                                                </div>
                                                <div className="latest-product__item__text">
                                                    <h6>Crab Pool Security</h6>
                                                    <span>$30.00</span>
                                                </div>
                                            </a>
                                            <a href="#" className="latest-product__item">
                                                <div className="latest-product__item__pic">
                                                    <img src={`${process.env.REACT_APP_STORAGE_URL}img/latest-product/lp-1.jpg`} alt />
                                                </div>
                                                <div className="latest-product__item__text">
                                                    <h6>Crab Pool Security</h6>
                                                    <span>$30.00</span>
                                                </div>
                                            </a>
                                            <a href="#" className="latest-product__item">
                                                <div className="latest-product__item__pic">
                                                    <img src={`${process.env.REACT_APP_STORAGE_URL}img/latest-product/lp-1.jpg`} alt />
                                                </div>
                                                <div className="latest-product__item__text">
                                                    <h6>Crab Pool Security</h6>
                                                    <span>$30.00</span>
                                                </div>
                                            </a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="latest-prdouct__slider__item">
                                            <a href="#" className="latest-product__item">
                                                <div className="latest-product__item__pic">
                                                    <img src={`${process.env.REACT_APP_STORAGE_URL}img/latest-product/lp-2.jpg`} alt />
                                                </div>
                                                <div className="latest-product__item__text">
                                                    <h6>Crab Pool Security</h6>
                                                    <span>$30.00</span>
                                                </div>
                                            </a>
                                            <a href="#" className="latest-product__item">
                                                <div className="latest-product__item__pic">
                                                    <img src={`${process.env.REACT_APP_STORAGE_URL}img/latest-product/lp-2.jpg`} alt />
                                                </div>
                                                <div className="latest-product__item__text">
                                                    <h6>Crab Pool Security</h6>
                                                    <span>$30.00</span>
                                                </div>
                                            </a>
                                            <a href="#" className="latest-product__item">
                                                <div className="latest-product__item__pic">
                                                    <img src={`${process.env.REACT_APP_STORAGE_URL}img/latest-product/lp-2.jpg`} alt />
                                                </div>
                                                <div className="latest-product__item__text">
                                                    <h6>Crab Pool Security</h6>
                                                    <span>$30.00</span>
                                                </div>
                                            </a>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="latest-product__text">
                                <h4>Review Products</h4>
                                <Swiper
                                    className="latest-product__slider"
                                    slidesPerView={1}
                                    // navigation={true}
                                    // navigation={{
                                    //     prevEl: '.swiper__button__product.swiper__prev',
                                    //     nextEl: '.swiper__button__product.swiper__next',
                                    // }}
                                    loop={true}
                                    modules={[Navigation]}
                                >

                                    <SwiperSlide>
                                        <div className="latest-prdouct__slider__item">
                                            <a href="#" className="latest-product__item">
                                                <div className="latest-product__item__pic">
                                                    <img src={`${process.env.REACT_APP_STORAGE_URL}img/latest-product/lp-1.jpg`} alt />
                                                </div>
                                                <div className="latest-product__item__text">
                                                    <h6>Crab Pool Security</h6>
                                                    <span>$30.00</span>
                                                </div>
                                            </a>
                                            <a href="#" className="latest-product__item">
                                                <div className="latest-product__item__pic">
                                                    <img src={`${process.env.REACT_APP_STORAGE_URL}img/latest-product/lp-1.jpg`} alt />
                                                </div>
                                                <div className="latest-product__item__text">
                                                    <h6>Crab Pool Security</h6>
                                                    <span>$30.00</span>
                                                </div>
                                            </a>
                                            <a href="#" className="latest-product__item">
                                                <div className="latest-product__item__pic">
                                                    <img src={`${process.env.REACT_APP_STORAGE_URL}img/latest-product/lp-1.jpg`} alt />
                                                </div>
                                                <div className="latest-product__item__text">
                                                    <h6>Crab Pool Security</h6>
                                                    <span>$30.00</span>
                                                </div>
                                            </a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="latest-prdouct__slider__item">
                                            <a href="#" className="latest-product__item">
                                                <div className="latest-product__item__pic">
                                                    <img src={`${process.env.REACT_APP_STORAGE_URL}img/latest-product/lp-2.jpg`} alt />
                                                </div>
                                                <div className="latest-product__item__text">
                                                    <h6>Crab Pool Security</h6>
                                                    <span>$30.00</span>
                                                </div>
                                            </a>
                                            <a href="#" className="latest-product__item">
                                                <div className="latest-product__item__pic">
                                                    <img src={`${process.env.REACT_APP_STORAGE_URL}img/latest-product/lp-2.jpg`} alt />
                                                </div>
                                                <div className="latest-product__item__text">
                                                    <h6>Crab Pool Security</h6>
                                                    <span>$30.00</span>
                                                </div>
                                            </a>
                                            <a href="#" className="latest-product__item">
                                                <div className="latest-product__item__pic">
                                                    <img src={`${process.env.REACT_APP_STORAGE_URL}img/latest-product/lp-2.jpg`} alt />
                                                </div>
                                                <div className="latest-product__item__text">
                                                    <h6>Crab Pool Security</h6>
                                                    <span>$30.00</span>
                                                </div>
                                            </a>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="from-blog spad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title from-blog__title">
                                <h2>From The Blog</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-6">
                            <div className="blog__item">
                                <div className="blog__item__pic">
                                    <img src={`${process.env.REACT_APP_STORAGE_URL}img/blog/blog-1.jpg`} alt />
                                </div>
                                <div className="blog__item__text">
                                    <ul>
                                        <li><i><FaCalendar /></i> May 4,2019</li>
                                        <li><i><FaComment /></i> 5</li>
                                    </ul>
                                    <h5><a href="#">Cooking tips make cooking simple</a></h5>
                                    <p>Sed quia non numquam modi tempora indunt ut labore et dolore magnam aliquam quaerat </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6">
                            <div className="blog__item">
                                <div className="blog__item__pic">
                                    <img src={`${process.env.REACT_APP_STORAGE_URL}img/blog/blog-2.jpg`} alt />
                                </div>
                                <div className="blog__item__text">
                                    <ul>
                                        <li><i><FaCalendar /></i> May 4,2019</li>
                                        <li><i><FaComment /></i> 5</li>
                                    </ul>
                                    <h5><a href="#">6 ways to prepare breakfast for 30</a></h5>
                                    <p>Sed quia non numquam modi tempora indunt ut labore et dolore magnam aliquam quaerat </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6">
                            <div className="blog__item">
                                <div className="blog__item__pic">
                                    <img src={`${process.env.REACT_APP_STORAGE_URL}img/blog/blog-3.jpg`} alt />
                                </div>
                                <div className="blog__item__text">
                                    <ul>
                                        <li><i><FaCalendar /></i> May 4,2019</li>
                                        <li><i><FaComment /></i> 5</li>
                                    </ul>
                                    <h5><a href="#">Visit the clean farm in the US</a></h5>
                                    <p>Sed quia non numquam modi tempora indunt ut labore et dolore magnam aliquam quaerat </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

        </>
    )
}

export default LandingPage