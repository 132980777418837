import React, { useEffect, useState } from 'react'
import Breadcrumb from '../components/breadcrumb/Breadcrumb'
import { FaTag } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { NumberFormat } from '../utils/NumberFormat';
import HttpService from '../services/HttpService';
import { useFormik } from 'formik';
import { placeOrderAction } from '../redux/actions/MarketActions';
import { useNavigate } from 'react-router-dom';

const Checkout = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  var [shippingData, setShippingData] = useState(null)

  const cartData = useSelector(
    (state) => state.cartData
  );

  useEffect(() => {
    fetchShippingAddress();
  }, []);

  const fetchShippingAddress = async () => {
    const http = new HttpService();
    let profileUrl = "checkoutaddress";
    const tokenId = "user-token";
    return http.getData(profileUrl, tokenId).then(data => {
      console.log(data);
      if (data.success) {
        setShippingData(data.data)
      }
      return data;
    }).catch((error) => {
      console.log(error);
      return error;
    });
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: shippingData?.first_name || "",
      last_name: shippingData?.last_name || "",
      address: shippingData?.address || "",
      phone: shippingData?.phone || "",
      email: shippingData?.email || "",
      notes: '',
      product_id: cartData && cartData.data.filter(product => product.qty > 0).map(cart => cart.product_id),
      qty: cartData && cartData.data.filter(product => product.qty > 0).map(cart => cart.qty),
    },
    onSubmit: values => {
      // alert(JSON.stringify(values, null, 2));
      // e.preventDefault();
        // console.log(values);
        dispatch(placeOrderAction(values,navigate));
    },
  });

  return (
    <>
      <Breadcrumb backgroundImage={'img/breadcrumb.jpg'} currentPage={'Checkout'} title={'Checkout'} />

      <section className="checkout spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h6><span><FaTag /></span> Have a coupon? <a href="#">Click here</a> to enter your code
              </h6>
            </div>
          </div>
          <div className="checkout__form">
            <h4>Billing Details</h4>
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-lg-8 col-md-6">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="checkout__input">
                        <p>First Name<span>*</span></p>
                        <input type="text" name="first_name" onChange={formik.handleChange}
                          value={formik.values.first_name} readOnly />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="checkout__input">
                        <p>Last Name<span>*</span></p>
                        <input type="text" name="last_name" onChange={formik.handleChange}
                          value={formik.values.last_name}  readOnly />
                      </div>
                    </div>
                  </div>
                  {/* <div className="checkout__input">
                    <p>Country<span>*</span></p>
                    <input type="text" />
                  </div> */}
                  <div className="checkout__input">
                    <p>Address<span>*</span></p>
                    <input type="text" placeholder="Street Address" className="checkout__input__add" name="address" onChange={formik.handleChange}
                          value={formik.values.address}  readOnly />
                    <input type="text" placeholder="Apartment, suite, unite ect (optinal)" readOnly />
                  </div>
                  {/* <div className="checkout__input">
                    <p>Town/City<span>*</span></p>
                    <input type="text" />
                  </div>
                  <div className="checkout__input">
                    <p>Country/State<span>*</span></p>
                    <input type="text" />
                  </div>
                  <div className="checkout__input">
                    <p>Postcode / ZIP<span>*</span></p>
                    <input type="text" />
                  </div> */}
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="checkout__input">
                        <p>Phone<span>*</span></p>
                        <input type="text" name='phone' onChange={formik.handleChange}
                          value={formik.values.phone}  readOnly />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="checkout__input">
                        <p>Email<span>*</span></p>
                        <input type="text" name='email' onChange={formik.handleChange}
                          value={formik.values.email}  readOnly />
                      </div>
                    </div>
                  </div>
                  {/* <div className="checkout__input__checkbox">
                    <label htmlFor="acc">
                      Create an account?
                      <input type="checkbox" id="acc" />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <p>Create an account by entering the information below. If you are a returning customer
                    please login at the top of the page</p>
                  <div className="checkout__input">
                    <p>Account Password<span>*</span></p>
                    <input type="text" />
                  </div>
                  <div className="checkout__input__checkbox">
                    <label htmlFor="diff-acc">
                      Ship to a different address?
                      <input type="checkbox" id="diff-acc" />
                      <span className="checkmark" />
                    </label>
                  </div> */}
                  <div className="checkout__input">
                    <p>Order notes<span>*</span></p>
                    <input type="text" name='notes' onChange={formik.handleChange}
                          value={formik.values.notes} placeholder="Notes about your order, e.g. special notes for delivery." />
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="checkout__order">
                    <h4>Your Order</h4>
                    {/* <div className="checkout__order__products">Products <span>Total</span></div> */}
                    {/* <ul>
              {cartData && cartData.data.map((item, i) => (
                <li key={i}>{item.name} <span>{item.discount_price ? item.discount_price * item.qty : item.price * item.qty}</span></li>
                    ))}
              </ul> */}
                    <table>
                      <thead>
                        <td>Product</td>
                        <td>Price</td>
                      </thead>
                      {cartData && cartData.data.filter(product => product.qty > 0).map((item, i) => (
                        <tr key={i}>
                          <td>{item.name} <input type="hidden" name={`product_id[${i}]`} onChange={formik.handleChange}
                          value={formik.values.product_id[i]} /></td>
                          <td>{NumberFormat((item.discount_price != 0 ? item.discount_price * item.qty : item.price * item.qty), 'IDR')}  <input type="hidden" name={`qty[${i}]`} onChange={formik.handleChange}
                          value={formik.values.qty[i]} /></td>
                        </tr>
                      ))}
                    </table>
                    {/* <div className="checkout__order__subtotal">Subtotal <span>$750.99</span></div> */}
                    <div className="checkout__order__total">Total <span>{cartData && NumberFormat(cartData.total_price, 'IDR ')}</span></div>
                    {/* <div className="checkout__input__checkbox">
                      <label htmlFor="acc-or">
                        Create an account?
                        <input type="checkbox" id="acc-or" />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adip elit, sed do eiusmod tempor incididunt
                      ut labore et dolore magna aliqua.</p>
                    <div className="checkout__input__checkbox">
                      <label htmlFor="payment">
                        Check Payment
                        <input type="checkbox" id="payment" />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="checkout__input__checkbox">
                      <label htmlFor="paypal">
                        Paypal
                        <input type="checkbox" id="paypal" />
                        <span className="checkmark" />
                      </label>
                    </div> */}
                    <button type="submit" className="site-btn" >PLACE ORDER</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>

    </>
  )
}

export default Checkout