import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import Footer from '../components/footer/Footer'
import Header from '../components/header/Header'
import About from '../pages/About'
import Cart from '../pages/Cart'
import Checkout from '../pages/Checkout'
import LandingPage from '../pages/LandingPage'
import OrderDetail from '../pages/Order/OrderDetail'
import OrderList from '../pages/Order/OrderList'
import ProductDetail from '../pages/ProductDetail'
import Shop from '../pages/Shop'
import { LoadProfileAction } from '../redux/actions/AuthActions'
import { FetchCartAction } from '../redux/actions/CartActions'

const Store = () => {

  const dispatch = useDispatch();
    useEffect(() => {
        dispatch(FetchCartAction());
        dispatch(LoadProfileAction());
        return () => {};
    }, [dispatch]);

  return (
    <>
        <Header/>
        <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/product/:id" element={<ProductDetail />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/order/" exact element={<OrderList />} />
            <Route path="/order/:id" element={<OrderDetail />} />
            <Route path="/about_us" element={<About />} />
            <Route path="/*" element={<Navigate to="/404" replace />}/>
        </Routes>
        <Footer/>
    </>
  )
}

export default Store