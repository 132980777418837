import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { AddCartAction } from '../../../redux/actions/CartActions';
import { NumberFormat } from '../../../utils/NumberFormat'

const CartItem = ({ item },) => {
    var { id, name, price, discount_price, thumbnail_img, qty } = item
    const dispatch = useDispatch();
    var [cartQty, setCartQty] = useState(qty);

    const updateQty = (type,num = null) => {
        var newQty = cartQty;
        if (num == null) {
            if (type == '+') {
                newQty = cartQty + 1;
            } else if (type == '-') {
                newQty = cartQty - 1;
            }
        } else {
            newQty = num.target.value;
        }
        setCartQty(newQty);
        dispatch(AddCartAction(id,newQty));
    }
    return (
        <tr>
            <td className="shoping__cart__item">
                <img src={thumbnail_img} alt={name} />
                <h5>{name}</h5>
            </td>
            <td className="shoping__cart__price">
                {discount_price != 0 ?
                    <>
                        <span className='text-danger'><s>{NumberFormat(price, 'IDR')}</s></span>
                        {NumberFormat(discount_price, 'IDR')}

                    </>
                    :
                    <>{NumberFormat(price, 'IDR')}</>
                }
            </td>
            <td className="shoping__cart__quantity">
                <div className="quantity">
                    <div className="pro-qty">
                        <span className='dec qtybtn' onClick={() => updateQty('-')}>-</span>
                        <input type="text" value={cartQty} onChange={(e) => updateQty(null,e)}/>
                        <span className='inc qtybtn' onClick={() => updateQty('+')}>+</span>
                    </div>
                </div>
            </td>
            <td className="shoping__cart__total">
                {discount_price != 0 ?
                    NumberFormat(discount_price * qty, 'IDR')

                    :
                    NumberFormat(price * qty, 'IDR')
                }
            </td>
            <td className="shoping__cart__item__close">
                <span className="icon_close" />
            </td>
        </tr>
    )
}

export default CartItem