import React, { useEffect, useState } from 'react'
import Breadcrumb from '../components/breadcrumb/Breadcrumb'

import { FaStar, FaStarHalfAlt, FaRegHeart } from "react-icons/fa";
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";
import { Tab, Tabs } from 'react-bootstrap';
import HttpService from '../services/HttpService';
import { NumberFormat } from '../utils/NumberFormat';
import parse from 'html-react-parser';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AddCartAction } from '../redux/actions/CartActions';


const ProductDetail = () => {

    let params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [key, setKey] = useState('description');
    var [thumbsSwiper, setThumbsSwiper] = useState(null);
    var [qty, setQty] = useState(1);

    var [productDetail, setProductDetail] = useState(null);
    useEffect(() => {
        getProductDetailData();
    }, []);

    const getProductDetailData = async () => {
        const http = new HttpService();
        const url = `product/${params.id}`;
        const dataFetch = await http
            .getDataClient(url)
            .then((data) => {
                return data;
            })
            .catch((error) => {
                return error;
            });
        console.log(dataFetch)
        if (dataFetch.success) {
            setProductDetail(dataFetch.data);
        } else {
            setProductDetail(null);
        }
    };

    var userData = useSelector(
        (state) => state.userAuth
    );

    const addToCart = async (qty) => {

        if (userData && userData.loggedIn && userData.currentUser) {
            // alert("Logged IN")
            dispatch(AddCartAction(productDetail.id,qty,true));
        } else {
            // alert("Logged OUT")
            navigate('/login')
        }
    };

    return (
        <>
            <div>
                {productDetail && <section className="product-details spad">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="product__details__pic">
                                    <>
                                        <Swiper
                                            loop={true}
                                            style={{
                                                "--swiper-navigation-color": "#fff",
                                                "--swiper-pagination-color": "#fff",
                                            }}
                                            spaceBetween={10}
                                            navigation={true}
                                            thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                                            modules={[FreeMode, Navigation, Thumbs]}
                                            className="mySwiper2"
                                        >
                                            {productDetail.imagesArr.map((img,i)=>(
                                            <SwiperSlide key={i}>
                                                <img src={img} />
                                            </SwiperSlide>
                                            ))}
                                        </Swiper>
                                        <Swiper
                                            loop={true}
                                            onSwiper={setThumbsSwiper}
                                            spaceBetween={10}
                                            slidesPerView={4}
                                            freeMode={true}
                                            watchSlidesProgress={true}
                                            modules={[FreeMode, Navigation, Thumbs]}
                                            className="mySwiper mt-2"
                                        >
                                            {productDetail.imagesArr.map((img,i)=>(
                                            <SwiperSlide key={i}>
                                                <img src={img} />
                                            </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="product__details__text">
                                    <h3>{productDetail.name}</h3>
                                    <div className="product__details__rating">
                                        <i><FaStar /></i>
                                        <i><FaStar /></i>
                                        <i><FaStar /></i>
                                        <i><FaStar /></i>
                                        <i><FaStarHalfAlt /></i>
                                        <span>(18 reviews)</span>
                                    </div>
                                    <div className="product__details__price">{NumberFormat(productDetail?.price,'IDR')}</div>
                                    <div>
                                        <p>{parse(productDetail.short_description)}</p>
                                    </div>
                                    <div className="product__details__quantity">
                                        <div className="quantity">
                                            <div className="pro-qty">
                                                <span className='dec qtybtn' onClick={() => setQty(qty - 1)}>-</span>
                                                <input type="text" value={qty} readOnly />
                                                <span className='inc qtybtn' onClick={() => setQty(qty + 1)}>+</span>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="primary-btn" onClick={() => addToCart(qty)}>ADD TO CART</button>
                                    <button className="heart-icon"><span><FaRegHeart /></span></button>
                                    <ul>
                                        <li><b>Availability</b> <span>In Stock</span></li>
                                        <li><b>Shipping</b> <span>01 day shipping. <samp>Free pickup today</samp></span></li>
                                        <li><b>Weight</b> <span>0.5 kg</span></li>
                                        <li><b>Share on</b>
                                            <div className="share">
                                                <a href="#"><i className="fa fa-facebook" /></a>
                                                <a href="#"><i className="fa fa-twitter" /></a>
                                                <a href="#"><i className="fa fa-instagram" /></a>
                                                <a href="#"><i className="fa fa-pinterest" /></a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="product__details__tab">
                                    <Tabs
                                        id="controlled-tab-example"
                                        activeKey={key}
                                        onSelect={(k) => setKey(k)}
                                    >
                                        <Tab eventKey="description" title="Description">
                                            <div className="product__details__tab__desc">
                                                {parse(productDetail?.description)}
                                            </div>
                                        </Tab>
                                        <Tab eventKey="information" title="Information">
                                            <div className="product__details__tab__desc">
                                                <h6>Products Infomation</h6>
                                                <p>Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui.
                                                    Pellentesque in ipsum id orci porta dapibus. Proin eget tortor risus.
                                                    Vivamus suscipit tortor eget felis porttitor volutpat. Vestibulum ac diam
                                                    sit amet quam vehicula elementum sed sit amet dui. Donec rutrum congue leo
                                                    eget malesuada. Vivamus suscipit tortor eget felis porttitor volutpat.
                                                    Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Praesent
                                                    sapien massa, convallis a pellentesque nec, egestas non nisi. Vestibulum ac
                                                    diam sit amet quam vehicula elementum sed sit amet dui. Vestibulum ante
                                                    ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae;
                                                    Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.
                                                    Proin eget tortor risus.</p>
                                                <p>Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Lorem
                                                    ipsum dolor sit amet, consectetur adipiscing elit. Mauris blandit aliquet
                                                    elit, eget tincidunt nibh pulvinar a. Cras ultricies ligula sed magna dictum
                                                    porta. Cras ultricies ligula sed magna dictum porta. Sed porttitor lectus
                                                    nibh. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.</p>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="review" title="Reviews(1)">
                                            <div className="product__details__tab__desc">
                                                <h6>Products Infomation</h6>
                                                <p>Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui.
                                                    Pellentesque in ipsum id orci porta dapibus. Proin eget tortor risus.
                                                    Vivamus suscipit tortor eget felis porttitor volutpat. Vestibulum ac diam
                                                    sit amet quam vehicula elementum sed sit amet dui. Donec rutrum congue leo
                                                    eget malesuada. Vivamus suscipit tortor eget felis porttitor volutpat.
                                                    Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Praesent
                                                    sapien massa, convallis a pellentesque nec, egestas non nisi. Vestibulum ac
                                                    diam sit amet quam vehicula elementum sed sit amet dui. Vestibulum ante
                                                    ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae;
                                                    Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.
                                                    Proin eget tortor risus.</p>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>}
                <section className="related-product">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title related__product__title">
                                    <h2>Related Product</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="product__item">
                                    <div className="product__item__pic set-bg" data-setbg="img/product/product-1.jpg" style={{ backgroundImage: `url("${process.env.REACT_APP_STORAGE_URL}img/product/product-1.jpg")` }}>
                                        <ul className="product__item__pic__hover">
                                            <li><a href="#"><i className="fa fa-heart" /></a></li>
                                            <li><a href="#"><i className="fa fa-retweet" /></a></li>
                                            <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                                        </ul>
                                    </div>
                                    <div className="product__item__text">
                                        <h6><a href="#">Crab Pool Security</a></h6>
                                        <h5>$30.00</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="product__item">
                                    <div className="product__item__pic set-bg" data-setbg="img/product/product-1.jpg" style={{ backgroundImage: `url("${process.env.REACT_APP_STORAGE_URL}img/product/product-2.jpg")` }}>
                                        <ul className="product__item__pic__hover">
                                            <li><a href="#"><i className="fa fa-heart" /></a></li>
                                            <li><a href="#"><i className="fa fa-retweet" /></a></li>
                                            <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                                        </ul>
                                    </div>
                                    <div className="product__item__text">
                                        <h6><a href="#">Crab Pool Security</a></h6>
                                        <h5>$30.00</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="product__item">
                                    <div className="product__item__pic set-bg" data-setbg="img/product/product-1.jpg" style={{ backgroundImage: `url("${process.env.REACT_APP_STORAGE_URL}img/product/product-3.jpg")` }}>
                                        <ul className="product__item__pic__hover">
                                            <li><a href="#"><i className="fa fa-heart" /></a></li>
                                            <li><a href="#"><i className="fa fa-retweet" /></a></li>
                                            <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                                        </ul>
                                    </div>
                                    <div className="product__item__text">
                                        <h6><a href="#">Crab Pool Security</a></h6>
                                        <h5>$30.00</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="product__item">
                                    <div className="product__item__pic set-bg" data-setbg="img/product/product-1.jpg" style={{ backgroundImage: `url("${process.env.REACT_APP_STORAGE_URL}img/product/product-4.jpg")` }}>
                                        <ul className="product__item__pic__hover">
                                            <li><a href="#"><i className="fa fa-heart" /></a></li>
                                            <li><a href="#"><i className="fa fa-retweet" /></a></li>
                                            <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                                        </ul>
                                    </div>
                                    <div className="product__item__text">
                                        <h6><a href="#">Crab Pool Security</a></h6>
                                        <h5>$30.00</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    )
}

export default ProductDetail