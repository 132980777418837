import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'

const ProductHero = ({ data }) => {
    const {thumbnail_img,name,slug} = data || {}
    const bgHero = data ? { backgroundImage: `url("${thumbnail_img}")`, backgroundPosition:'center', } : { backgroundColor: `#fdfdfd`}
    return (
        <section className="hero">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="hero__item set-bg" data-setbg="" style={bgHero}>
                            <div className={`hero__text ${data ? '' : 'col-lg-12'}`}>
                                <span className='secondary__text'>{data ? `LIMITED EDITION` : <Skeleton />}</span>
                                <h2>{name || <Skeleton />}</h2>
                                <p>{data ? `Don't miss it` : <Skeleton />}</p>
                                {data ? <Link className="primary-btn" to={`product/${slug}`} >SHOP NOW</Link> : <Skeleton />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProductHero