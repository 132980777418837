import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Breadcrumb from '../components/breadcrumb/Breadcrumb'
import CartItem from '../components/molecules/Cart/CartItem';
import { NumberFormat } from '../utils/NumberFormat';

const Cart = () => {
  
  const cartData = useSelector(
    (state) => state.cartData
    );

  return (
    <>
      <Breadcrumb backgroundImage={'img/breadcrumb.jpg'} currentPage={'Cart'} title={'Cart'} />

      <section className="shoping-cart spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="shoping__cart__table">
                <table>
                  <thead>
                    <tr>
                      <th className="shoping__product">Products</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Total</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {cartData && cartData.data.map((item, i) => (
                      <CartItem key={i} item={item}/>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="shoping__cart__btns">
                <Link to="/shop" className="primary-btn cart-btn">CONTINUE SHOPPING</Link>
              <div className="shoping__continue">
                <div className="shoping__discount">
                  <h5>Discount Codes</h5>
                  <form action="#">
                    <input type="text" placeholder="Enter your coupon code" />
                    <button type="submit" className="site-btn">APPLY COUPON</button>
                  </form>
                </div>
              </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="shoping__checkout">
                <h5>Cart Total</h5>
                <ul>
                  {/* <li>Subtotal <span>$454.98</span></li> */}
                  <li>Total <span>{cartData && NumberFormat(cartData.total_price,'IDR ')}</span></li>
                </ul>
                <Link to="/checkout" className="primary-btn">PROCEED TO CHECKOUT</Link>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Cart