import React, { useState } from 'react'
import { FaEnvelope, FaBars, FaChevronDown, FaHeart, FaShoppingBag, FaUserAlt, FaFacebookF, FaInstagram, FaPhoneAlt, FaSignInAlt } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { LogoutAction } from '../../redux/actions/AuthActions';
import { NumberFormat } from '../../utils/NumberFormat';

const Header = () => {

    const history = useNavigate();
    const dispatch = useDispatch();
    const [mobileMenu, setMobileMenu] = useState(false)

    const cartData = useSelector(
        (state) => state.cartData
    );
    const userData = useSelector(
        (state) => state.userAuth
    );

    const { name: username, email } = userData.currentUser || {}

    return (
        <>
            <div>
                <div className={`humberger__menu__overlay ${mobileMenu ? 'active' : null}`} onClick={() => { setMobileMenu(!mobileMenu) }} />
                <div className={`humberger__menu__wrapper ${mobileMenu ? 'show__humberger__menu__wrapper' : null}`}>
                    <div className="humberger__menu__logo">
                        <Link to="/"><img src={process.env.REACT_APP_STORAGE_URL + 'img/logo.png'} alt /></Link>
                    </div>
                    <div className="humberger__menu__cart">
                        <ul>
                            <li><a href="#"><i><FaHeart /></i> <span>1</span></a></li>
                            <li><a href="#"><i><FaShoppingBag /></i> <span>3</span></a></li>
                        </ul>
                        <div className="header__cart__price">item: <span>$150.00</span></div>
                    </div>
                    <div className="humberger__menu__widget">
                        <div className="header__top__right__language">
                            <img src={process.env.REACT_APP_STORAGE_URL + "img/language.png"} alt />
                            <div>English</div>
                            <FaChevronDown className='arrow_carrot-down' />
                            {/* <span className="arrow_carrot-down" /> */}
                            <ul>
                                <li><a href="#">Spanis</a></li>
                                <li><a href="#">English</a></li>
                            </ul>
                        </div>
                        <div className="header__top__right__auth pl-2 header__top__right__language">
                            {userData.loggedIn ?
                                <><FaUserAlt />  {username}
                                    <ul>
                                        <li><a href='#' onClick={() => { }}>Logout</a></li>
                                    </ul></>
                                :
                                <Link to="/login"><FaSignInAlt />  Login</Link>

                            }
                        </div>
                    </div>

                    <nav class="humberger__menu__nav mobile-menu">
                        <ul>
                            {/* <li className="active"><Link to={`/`}>Home</Link></li> */}
                            <li><Link to={`/`}>Home</Link></li>
                            <li><Link to={`/shop`}>Store</Link></li>
                            <li><a href="#">Shop</a>
                                <ul className="header__menu__dropdown">
                                    <li><Link to={`/product/123`}>Product</Link></li>
                                    <li><Link to={`/cart`}>Cart</Link></li>
                                    <li><Link to={`/checkout`}>Checkout</Link></li>
                                </ul>
                            </li>
                            <li><Link to={`/about_us`}>About Us</Link></li>
                        </ul>
                    </nav>

                    <div id="mobile-menu-wrap">
                        <div class="slicknav_menu">
                            <a href="#" aria-haspopup="true" role="button" tabindex="0" class="slicknav_btn slicknav_collapsed">
                                <span class="slicknav_menutxt">MENU</span>
                                <span class="slicknav_icon">
                                    <span class="slicknav_icon-bar"></span>
                                    <span class="slicknav_icon-bar"></span>
                                    <span class="slicknav_icon-bar"></span>
                                </span>
                            </a>
                            <nav class="slicknav_nav slicknav_hidden" aria-hidden="true" role="menu">
                                <ul>
                                    <li><Link to={`/`}>Home</Link></li>
                                    <li><Link to={`/shop`}>Store</Link></li>
                                    {/* <li><a href="#">Shop</a>
                                        <ul className="header__menu__dropdown">
                                            <li><Link to={`/product/123`}>Product</Link></li>
                                            <li><Link to={`/cart`}>Cart</Link></li>
                                            <li><Link to={`/checkout`}>Checkout</Link></li>
                                        </ul>
                                    </li> */}
                                    <li><Link to={`/about_us`}>About Us</Link></li>
                                    <li><Link to={`/order`}>Order</Link></li>
                                </ul>
                            </nav>
                        </div>
                    </div>

                    <div className="header__top__right__social">
                        <a href="#"><i><FaFacebookF /></i></a>
                        <a href="#"><i><FaInstagram /></i></a>
                    </div>
                    <div className="humberger__menu__contact">
                        <ul>
                            <li><FaEnvelope /> hello@toora.com</li>
                            <li>Free Shipping for all Order of $99</li>
                        </ul>
                    </div>
                </div>
            </div>

            <header className="header">
                <div className="header__top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="header__top__left">
                                    <ul>
                                        <li><FaEnvelope /> hello@toora.com</li>
                                        <li>Free Shipping for all Order of $99</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="header__top__right">
                                    <div className="header__top__right__social">
                                        <a href="#"><i><FaFacebookF /></i></a>
                                        <a href="#"><i><FaInstagram /></i></a>
                                    </div>
                                    <div className="header__top__right__language">
                                        <img src={process.env.REACT_APP_STORAGE_URL + "img/language.png"} alt />
                                        <div>English</div>
                                        <FaChevronDown className='arrow_carrot-down' />
                                        {/* <span className="arrow_carrot-down" /> */}
                                        <ul>
                                            <li><a href="#">Spanis</a></li>
                                            <li><a href="#">English</a></li>
                                        </ul>
                                    </div>
                                    <div className="header__top__right__auth header__top__right__language">
                                        {userData.loggedIn ?
                                            <><FaUserAlt />  {username}
                                                <ul>
                                                    <li>
                                                        <a href='#' onClick={() => {dispatch(LogoutAction(history))}}>Logout</a>
                                                    </li>
                                                </ul></>
                                            :
                                            <Link to="/login"><FaSignInAlt />  Login</Link>

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="header__logo">
                                <Link to="/"><img src={process.env.REACT_APP_STORAGE_URL + 'img/logo.png'} alt /></Link>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <nav className="header__menu">
                                <ul>
                                    {/* <li className="active"><Link to={`/`}>Home</Link></li> */}
                                    <li><Link to={`/`}>Home</Link></li>
                                    <li><Link to={`/shop`}>Store</Link></li>
                                    {/* <li><a href="#">Shop</a>
                                        <ul className="header__menu__dropdown">
                                            <li><Link to={`/product/123`}>Product</Link></li>
                                            <li><Link to={`/cart`}>Cart</Link></li>
                                            <li><Link to={`/checkout`}>Checkout</Link></li>
                                        </ul>
                                    </li> */}
                                    <li><Link to={`/about_us`}>About Us</Link></li>
                                    <li><Link to={`/order`}>Order</Link></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-lg-3">
                            <div className="header__cart">
                                <ul>
                                    <li><a href="#"><i><FaHeart /></i> <span>1</span></a></li>
                                    <li><Link to='/cart'><i><FaShoppingBag /></i> <span>{cartData.total}</span></Link></li>
                                </ul>
                                <div className="header__cart__price">item: <span>{NumberFormat(cartData.total_price, 'IDR')}</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="humberger__open" onClick={() => { setMobileMenu(!mobileMenu) }}>
                        <i><FaBars /></i>
                    </div>
                </div>
            </header>

            <section className="hero hero-normal">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-lg-3">
                            <div className="hero__categories">
                                <div className="hero__categories__all">
                                    <i><FaBars /></i>
                                    <span>All departments</span>
                                    <FaChevronDown className='arrow__down' />
                                </div>
                                <ul style={{ display: 'none' }}>
                                    <li><a href="#">Fresh Meat</a></li>
                                    <li><a href="#">Vegetables</a></li>
                                    <li><a href="#">Fruit &amp; Nut Gifts</a></li>
                                    <li><a href="#">Fresh Berries</a></li>
                                    <li><a href="#">Ocean Foods</a></li>
                                    <li><a href="#">Butter &amp; Eggs</a></li>
                                    <li><a href="#">Fastfood</a></li>
                                    <li><a href="#">Fresh Onion</a></li>
                                    <li><a href="#">Papayaya &amp; Crisps</a></li>
                                    <li><a href="#">Oatmeal</a></li>
                                    <li><a href="#">Fresh Bananas</a></li>
                                </ul>
                            </div>
                        </div> */}
                        <div className="col-lg-12">
                            <div className="hero__search">
                                <div className="hero__search__form">
                                    <form action="#">
                                        <div className="hero__search__categories">
                                            All Categories
                                            <span className="arrow_carrot-down" />
                                        </div>
                                        <input type="text" placeholder="What do yo u need?" />
                                        <button type="submit" className="site-btn">SEARCH</button>
                                    </form>
                                </div>
                                <div className="hero__search__phone">
                                    <div className="hero__search__phone__icon">
                                        <i><FaPhoneAlt /></i>
                                    </div>
                                    <div className="hero__search__phone__text">
                                        <h5>+62 11.188.888</h5>
                                        <span>support 24/7 time</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Header