import React from 'react'
import Breadcrumb from '../components/breadcrumb/Breadcrumb'
import { FaPhoneAlt, FaMapMarkerAlt, FaRegClock, FaRegEnvelope } from "react-icons/fa";
import { Card } from 'react-bootstrap';

const About = () => {
    return (
        <>
            <Breadcrumb backgroundImage={'img/breadcrumb.jpg'} currentPage={'About'} title={'About'} />
            <div>
                <section className="contact spad">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center mb-3">
                                <h2>Meet Our Leader</h2>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 text-center">
                                <Card >
                                    <Card.Img variant="top" src="https://deprakoso.com/img/Profile%20-%20Raka%20D%20Prakoso(Compressed).jpg" />
                                    <Card.Body>
                                        <Card.Title>Raka D Prakoso</Card.Title>
                                        <Card.Text>
                                            Founder
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 text-center">
                                <Card >
                                    <Card.Img variant="top" src={process.env.REACT_APP_STORAGE_URL+'img/founder/ilham bagas ibrahim.jpg'} />
                                    <Card.Body>
                                        <Card.Title>Ilham Bagas Ibrahim</Card.Title>
                                        <Card.Text>
                                            Founder
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 text-center">
                                <Card >
                                    <Card.Img variant="top" src={process.env.REACT_APP_STORAGE_URL+'img/founder/nur raditya mahardika.png'} />
                                    <Card.Body>
                                        <Card.Title>Nur Raditya Mahardika</Card.Title>
                                        <Card.Text>
                                            Founder
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="col-lg-12 text-center my-5">
                                <hr />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                                <div className="contact__widget">
                                    <span className="icon_phone"><FaPhoneAlt /></span>
                                    <h4>Phone</h4>
                                    <p>+01-3-8888-6868</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                                <div className="contact__widget">
                                    <span className="icon_pin_alt"><FaMapMarkerAlt /></span>
                                    <h4>Address</h4>
                                    <p>60-49 Road 11378 New York</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                                <div className="contact__widget">
                                    <span className="icon_clock_alt"><FaRegClock /></span>
                                    <h4>Open time</h4>
                                    <p>10:00 am to 23:00 pm</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                                <div className="contact__widget">
                                    <span className="icon_mail_alt"><FaRegEnvelope /></span>
                                    <h4>Email</h4>
                                    <p>hello@toora.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31708.235766424546!2d110.64878295819251!3d-6.580904862498824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7118d34b9ae3ab%3A0x9d3067f98797ae0f!2sJepara%2C%20Kec.%20Jepara%2C%20Kabupaten%20Jepara%2C%20Jawa%20Tengah!5e0!3m2!1sid!2sid!4v1651398410431!5m2!1sid!2sid" height={500} style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />
                    <div className="map-inside">
                        <i className="icon_pin" />
                        <div className="inside-widget">
                            <h4>Jepara</h4>
                            <ul>
                                <li>Phone: +62-345-6789</li>
                                <li>Add: Jepara, Central Java</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="contact-form spad">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="contact__form__title">
                                    <h2>Leave Message</h2>
                                </div>
                            </div>
                        </div>
                        <form action="#">
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <input type="text" placeholder="Your name" />
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <input type="text" placeholder="Your Email" />
                                </div>
                                <div className="col-lg-12 text-center">
                                    <textarea placeholder="Your message" defaultValue={""} />
                                    <button type="submit" className="site-btn">SEND MESSAGE</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </>
    )
}

export default About