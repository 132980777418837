import React from 'react'
import { Route, Routes } from 'react-router-dom'

import './App.scss'
import Store from './Layouts/Store'
import Login from './pages/Auth/Login'
import NotFound from './pages/NotFound'

const App = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/*" element={<Store />} />
      <Route path="/404*" element={<NotFound />} />
    </Routes>
  )
}

export default App