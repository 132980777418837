import HttpService from './HttpService';
export const RegisterUserService = (credentials) => {
    const http = new HttpService();
    let signupUrl = "users/register";
    return http.postData(credentials, signupUrl).then((data) => {
        console.log(data);
        return data;
    }).catch((error) => {
        return error;;
    })
}
export const LoginUserService = (credentials) => {
    const http = new HttpService();
    let loginUrl = "login";
    return http.postData(credentials, loginUrl).then((data) => {
        // console.log(data);
        return data;
    }).catch((error) => {
        return error;
    })
}
export const LogOutUserService = () => {
    const http = new HttpService();
    let url = "logout";
    const tokenId = "user-token";
    return http.postData({},url, tokenId).then((data) => {
        console.log(data);
        return data;
    }).catch((error) => {
        return error;
    })
}
export const LoadProfileService = () => {
    const http = new HttpService();
    let profileUrl = "profile";
    const tokenId = "user-token";
    return http.getData(profileUrl, tokenId).then(data => {
        console.log(data);
        return data;
    }).catch((error) => {
        console.log(error);
        return error;
    });
}
