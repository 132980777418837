import React, { useEffect, useState } from 'react'

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css/pagination";

import InputRange from '../components/atoms/range/InputRange';
import ReactSelect from 'react-select';

import { FaCaretRight,FaCaretLeft } from "react-icons/fa";
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import HttpService from '../services/HttpService';
import ProductThumbnail from '../components/molecules/ProductThumbnail';
import ReactPaginate from 'react-paginate';
import { useNavigate, useSearchParams } from 'react-router-dom';

var items = {} 
var totalProducts = 0

const Thumbnail = ({ products }) => {
  return (
    <>
      {products && products.map((product, i) => (
        <div className="col-lg-4 col-md-6 col-sm-6 mb-4" key={i}>
          <ProductThumbnail product={product} />
        </div>
      ))}
    </>
  );
}

const Shop = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  let currentPage = searchParams.get("page");
  const initialPage = currentPage ? parseInt(currentPage)-1 : 0;

  const [state, setState] = useState({ x: 200000 });

  const itemsPerPage = 24
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const navigate = useNavigate()


  const options = [
    { value: 'default', label: 'Default' },
    { value: 'lowest_price', label: 'Harga Termurah' },
    { value: 'terlaris', label: 'Terlaris' }
  ]

  const [flashSaleProducts, setFlashSaleProducts] = useState(null);
  const [products, setProducts] = useState(null);
  useEffect(() => {
    getFlashSaleProducts();
  }, []);

  const getFlashSaleProducts = async () => {
    const http = new HttpService();
    // const page = initialPage ? initialPage : 1;
    // const url = `product?page=${page}`;
    const url = `product`;
    const dataFetch = await http
      .getDataClient(url)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return error;
      });
    console.log(dataFetch)
    if (dataFetch.success) {
      setFlashSaleProducts(dataFetch.data);
      setProducts(dataFetch.data);
      // preparePaginateData(dataFetch.data);
      // items = dataFetch.data.data
      // totalProducts = dataFetch.data.total
    } else {
      setFlashSaleProducts(null);
      setProducts(null);
    }
  };

  const getPaginateProduct = async (page) => {
    const http = new HttpService();
    const url = `product?page=${page}`;
    const dataFetch = await http
    .getDataClient(url)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return error;
      });
    if (dataFetch.success) {
      // setProducts(dataFetch.data);
      preparePaginateData(dataFetch.data);
      items = dataFetch.data.data
      // setItemOffset(page);
    } else {
      setProducts(null);
    }
  };

  const preparePaginateData = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.data);
    setPageCount(items.last_page);
  }

  const handlePageClick = (event) => {
    navigate(`/shop?page=${parseInt(event.selected) + 1}`)
    const newOffset = (event.selected * itemsPerPage) % items.length;
    console.log(
      `User requested page number ${event.selected + 1}, which is offset ${newOffset}`
    );
    getPaginateProduct(parseInt(event.selected) + 1)
    // setItemOffset(newOffset);
  };

  useEffect(() => {
    if (products) {
      preparePaginateData(items);
    }
  }, [itemOffset, itemsPerPage]);

  return (
    <>
      <Breadcrumb backgroundImage={'img/breadcrumb.jpg'} currentPage={'Shop'} title={'Toora Shop'} />

      <section className="product spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-5 d-md-block d-none">
              <div className="sidebar">
                <div className="sidebar__item">
                  <h4>Department</h4>
                  <ul>
                    <li><a href="#">Fresh Meat</a></li>
                    <li><a href="#">Vegetables</a></li>
                    <li><a href="#">Fruit &amp; Nut Gifts</a></li>
                    <li><a href="#">Fresh Berries</a></li>
                    <li><a href="#">Ocean Foods</a></li>
                    <li><a href="#">Butter &amp; Eggs</a></li>
                    <li><a href="#">Fastfood</a></li>
                    <li><a href="#">Fresh Onion</a></li>
                    <li><a href="#">Papayaya &amp; Crisps</a></li>
                    <li><a href="#">Oatmeal</a></li>
                  </ul>
                </div>
                <div className="sidebar__item">
                  <h4>Price</h4>

                  <div className="price-range-wrap">
                    <InputRange />
                  </div>
                </div>
                <div className="sidebar__item sidebar__item__color--option">
                  <h4>Colors</h4>
                  <div className="sidebar__item__color sidebar__item__color--white">
                    <label htmlFor="white">
                      White
                      <input type="radio" id="white" />
                    </label>
                  </div>
                  <div className="sidebar__item__color sidebar__item__color--gray">
                    <label htmlFor="gray">
                      Gray
                      <input type="radio" id="gray" />
                    </label>
                  </div>
                  <div className="sidebar__item__color sidebar__item__color--red">
                    <label htmlFor="red">
                      Red
                      <input type="radio" id="red" />
                    </label>
                  </div>
                  <div className="sidebar__item__color sidebar__item__color--black">
                    <label htmlFor="black">
                      Black
                      <input type="radio" id="black" />
                    </label>
                  </div>
                  <div className="sidebar__item__color sidebar__item__color--blue">
                    <label htmlFor="blue">
                      Blue
                      <input type="radio" id="blue" />
                    </label>
                  </div>
                  <div className="sidebar__item__color sidebar__item__color--green">
                    <label htmlFor="green">
                      Green
                      <input type="radio" id="green" />
                    </label>
                  </div>
                </div>
                <div className="sidebar__item">
                  <h4>Popular Size</h4>
                  <div className="sidebar__item__size">
                    <label htmlFor="large">
                      Large
                      <input type="radio" id="large" />
                    </label>
                  </div>
                  <div className="sidebar__item__size">
                    <label htmlFor="medium">
                      Medium
                      <input type="radio" id="medium" />
                    </label>
                  </div>
                  <div className="sidebar__item__size">
                    <label htmlFor="small">
                      Small
                      <input type="radio" id="small" />
                    </label>
                  </div>
                  <div className="sidebar__item__size">
                    <label htmlFor="tiny">
                      Tiny
                      <input type="radio" id="tiny" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-7">
              <div className="product__discount">
                <div className="section-title product__discount__title">
                  <h2>Sale Off</h2>
                </div>
                <div className="row">
                  <Swiper
                    className="product__discount__slider pb-5"
                    slidesPerView={1}
                    breakpoints={{
                      1024: {
                        slidesPerView: 3,
                      },
                    }}
                    loop={true}
                    pagination={{ clickable: true }}
                    modules={[Pagination]}
                  >
                    {(flashSaleProducts && flashSaleProducts.data) && flashSaleProducts.data.filter(product => product.discount_price != 0).map((product, i) => (
                      <SwiperSlide key={i} className="col-lg-4">
                        <ProductThumbnail product={product} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
              <div className="filter__item">
                <div className="row">
                  <div className="col-lg-4 col-md-5">
                    <div className="filter__sort">
                      <span className='mr-2'>Sort By</span>
                      <span><ReactSelect options={options} /></span>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <div className="filter__found">
                      <h6><span>{products && products.total}</span> Products found</h6>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-3">
                    <div className="filter__option">
                      <span className="icon_grid-2x2" />
                      <span className="icon_ul" />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row">
                {(products && products.data) && products.data.map((product, i) => (
                  <div className="col-lg-4 col-md-6 col-sm-6 mb-4" key={i}>
                    <ProductThumbnail product={product} />
                  </div>
                ))}
              </div>
              <div className="product__pagination">
                <a href="#">1</a>
                <a href="#">2</a>
                <a href="#">3</a>
                <a href="#"><FaCaretRight className='mb-1' /></a>
              </div> */}
              <div className='row'><Thumbnail products={currentItems} /></div>
              <ReactPaginate
                breakLabel="..."
                nextLabel={<FaCaretRight className='mb-1' />}
                previousLabel={<FaCaretLeft className='mb-1' />}
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                renderOnZeroPageCount={null}
                containerClassName='product__pagination'
                initialPage={initialPage}
                // initialPage={2}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Shop