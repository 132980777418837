import React from 'react'
import { Link } from 'react-router-dom'
import { NumberFormat, PercentFormat } from '../../utils/NumberFormat'
import { FaRegHeart, FaRetweet, FaShoppingCart } from "react-icons/fa"

const ProductThumbnail = ({ product }) => {
    const { name, slug, thumbnail_img, price, discount_price } = product
    return (
            <div className="product__discount__item">
                <div className="product__discount__item__pic set-bg" data-setbg="img/featured/feature-1.jpg" style={{ backgroundImage: `url("${thumbnail_img}")` }}>
                    {discount_price != 0 &&
                        <div className="product__discount__percent">{PercentFormat(discount_price,price,0)}</div>}

                    <ul className="product__item__pic__hover">
                        <li><a href="#"><i><FaRegHeart /></i></a></li>
                        <li><a href="#"><i><FaRetweet /></i></a></li>
                        <li><a href="#"><i><FaShoppingCart /></i></a></li>
                    </ul>
                </div>
                <div className="product__discount__item__text">
                    {/* <span>Dried Fruit</span> */}
                    <h5><Link to={"/product/" + slug}>{name}</Link></h5>
                    <div className="product__item__price">
                        {discount_price != 0 || discount_price != 0.00  ? NumberFormat(discount_price, 'IDR') : NumberFormat(price, 'IDR')}
                        {/* {NumberFormat(discount_price || price, 'IDR')} */}
                        {discount_price != 0 && <span>{NumberFormat(price, 'IDR')}</span>}
                    </div>
                </div>
            </div>
    )
}

export default ProductThumbnail