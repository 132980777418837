import * as ActionTypes from '../ActionTypes';
import { FetchCartService, PostCartService } from '../../services/EcommerceService';

export const FetchCartAction = () => {
    return (dispatch) => {
        FetchCartService().then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: ActionTypes.GET_CART, res });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                console.log('Kondisi 2')
            } else {
                console.log('Kondisi 3')
            }
        }).catch((error) => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
        })
    }
}
export const AddCartAction = (id, amount,add=false) => {
    return (dispatch) => {
        PostCartService({id,amount,add}).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: ActionTypes.GET_CART, res });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                console.log('Kondisi 2')
            } else {
                console.log('Kondisi 3')
            }
        }).catch((error) => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
        })

        // dispatch({ type: ActionTypes.ADD_CART, data: { id, amount } })
    }
}
