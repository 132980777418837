import React from 'react'
import { Link } from 'react-router-dom'

const Breadcrumb = ({backgroundImage,currentPage,title}) => {
  return (
    <section className="breadcrumb-section set-bg" data-setbg="img/breadcrumb.jpg" style={{ backgroundImage: `url("${process.env.REACT_APP_STORAGE_URL}${backgroundImage}")` }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="breadcrumb__text">
                  <h2>{title}</h2>
                  <div className="breadcrumb__option">
                    <Link to="/">Home</Link>
                    <span>{currentPage}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
  )
}

export default Breadcrumb