import * as ActionTypes from "../ActionTypes";
const initState = {
  messageResponse: "",
  total: 0,
  total_price: 0,
  data: localStorage.getItem("cart") || [],
};
const CartReducer = (state = initState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_CART:
      return {
        ...state,
        // messageResponse: action.res,
        total: state.total+action.data.amount,
      };
    case ActionTypes.GET_CART:
      return {
        ...state,
        messageResponse: 'Data Fetched!',
        total: action.res.data.total,
        total_price: action.res.data.total_price,
        data: action.res.data.cart,
      };
    default:
      return state;
  }
};
export default CartReducer;
