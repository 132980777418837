import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import HttpService from '../../services/HttpService';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import { NumberFormat } from '../../utils/NumberFormat';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';

const OrderList = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    let params = useParams();
    var [orderData, setOrderData] = useState(null)

    useEffect(() => {
        fetchShippingAddress();
    }, []);

    const fetchShippingAddress = async () => {
        const http = new HttpService();
        let url = `order`;
        const tokenId = "user-token";
        return http.getData(url, tokenId).then(data => {
            console.log(data);
            if (data.success) {
                setOrderData(data.data)
            }
            return data;
        }).catch((error) => {
            console.log(error);
            return error;
        });
    }

    return (
        <>
            <Breadcrumb backgroundImage={'img/breadcrumb.jpg'} currentPage={'Orders'} title={'Orders'} />

            <section className="checkout spad">
                <div className="container">
                    <div className="checkout__form">
                        <h4>Order List</h4>
                        <div className="row">
                        
                            {!orderData && 
                            <div className="col-lg-12 mb-3">
                            <div className='card'>
                                <div className='card-body'>
                                    <h5 className='card-title'><Skeleton /></h5>
                                    <div><Skeleton />
                                    </div>
                                    <div><Skeleton /></div>
                                    <div><Skeleton /></div>
                                </div>
                                   <div className='card-footer'> <Skeleton /></div>
                            </div>
                        </div>}
                            {orderData && orderData.map((item, i) => (
                                <div className="col-lg-12 mb-3">
                                    <div className='card'>
                                        <div className='card-body'>
                                            <h5 className='card-title'>{item.order_id}</h5>
                                            <div>Total Price : {NumberFormat(item.payment.total_price,'IDR')}
                                            </div>
                                            <div>Ordered at : {moment(item.created_at).format('HH : mm, DD MMMM YYYY')}
                                                {/* {item.created_at . moment().format('MMMM Do YYYY')} */}
                                            </div>
                                            <div>Payment Status : 
                                                {item?.payment?.status == 'settlement' ?
                                                <span class="ml-2 badge badge-primary">Paid</span> : <span class="ml-2 badge badge-danger">Not Paid</span>
                                                }
                                            </div>
                                        </div>
                                           <div className='card-footer'> <Link to={`/order/${item.order_id}`} class="btn btn-primary btn-sm">Detail</Link></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default OrderList