import React, { useState } from 'react'
import { getTrackBackground, Range } from 'react-range';

const STEP = 1000;
const MIN = 100000;
const MAX = 5000000;

// Copy of TwoThumbs with `draggableTrack` prop added
const InputRange = ({rtl}) => {
  const [values, setValues] = useState([100000, 1000000]);
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap'
      }}
    >
      <Range
        draggableTrack
        values={values}
        step={STEP}
        min={MIN}
        max={MAX}
        rtl={rtl}
        onChange={(values) => {
          setValues(values);
        }}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '36px',
              display: 'flex',
              width: '100%'
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: '5px',
                width: '100%',
                borderRadius: '4px',
                background: getTrackBackground({
                  values,
                  colors: ['#ccc', '#dd2222', '#ccc'],
                  min: MIN,
                  max: MAX,
                  rtl
                }),
                alignSelf: 'center'
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '13px',
              width: '13px',
              borderRadius: '50%',
              backgroundColor: '#FFF',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 1px 10px rgb(0 0 0 / 20%)'
            }}
          >
          </div>
        )}
      />
      <output style={{ marginTop: '10px',fontSize:'16px',color:'#dd2222',fontWeight:'700' }} id="output">
        {/* {values[0].toFixed(1)} - {values[1].toFixed(1)} */}
        Rp. {values[0]} - Rp. {values[1]}
      </output>
    </div>
  );
};

export default InputRange;