import React, { useEffect, useState } from 'react'
import { FaTag } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import HttpService from '../../services/HttpService';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import { NumberFormat } from '../../utils/NumberFormat';
import Skeleton from 'react-loading-skeleton';

const OrderDetail = () => {
    let params = useParams();
    var [orderData, setOrderData] = useState(null)

    useEffect(() => {
        fetchShippingAddress();
    }, []);

    const fetchShippingAddress = async () => {
        const http = new HttpService();
        let url = `order/${params.id}`;
        const tokenId = "user-token";
        return http.getData(url, tokenId).then(data => {
            console.log(data);
            if (data.success) {
                setOrderData(data.data)
            }
            return data;
        }).catch((error) => {
            console.log(error);
            return error;
        });
    }

    return (
        <>
            <Breadcrumb backgroundImage={'img/breadcrumb.jpg'} currentPage={'Order Detail'} title={'Order Detail'} />

            <section className="checkout spad">
                <div className="container">
                    {orderData ? <div className="checkout__form">
                        <h4>Order Details : {orderData?.order_id || ""} {orderData ?
                        ((orderData?.payment?.status == 'settlement') ?
                                <span class="badge badge-primary">Paid</span> : <span class="badge badge-danger">Not Paid</span>) : <span class="badge badge-secondary">Loading...</span>
                        }</h4>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="checkout__input">
                                                <h5><b>Name</b></h5>
                                                <p>{orderData?.name_buyer || ""}</p>
                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-6'>
                                                    <div className="checkout__input">
                                                        <h5><b>Phone Number</b></h5>
                                                        <p>{orderData?.phone_buyer || ""}</p>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <div className="checkout__input">
                                                        <h5><b>Email</b></h5>
                                                        <p>{orderData?.email_buyer || ""}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="checkout__input">
                                                <h5><b>Address</b></h5>
                                                <p>{orderData?.address_buyer || ""}</p>
                                            </div>
                                            <div className="checkout__input">
                                                <h5><b>Notes</b></h5>
                                                <p>{orderData?.notes || "-"}</p>
                                            </div>
                                            <div className="checkout__order">
                                                <h4 className='border-0'>Your Order</h4>
                                                <table className='table'>
                                                    <thead>
                                                        <td>Product</td>
                                                        <td>Amount</td>
                                                        <td>Qty</td>
                                                        <td>Total Amount</td>
                                                    </thead>
                                                    {orderData && orderData.order_details.map((item, i) => (
                                                        <tr key={i}>
                                                            <td>{item?.product.name || '-'}</td>
                                                            <td>{NumberFormat((item.price), 'IDR')}</td>
                                                            <td>{item?.qty || '-'}</td>
                                                            <td>{NumberFormat((item.price * item.qty), 'IDR')} </td>
                                                        </tr>
                                                    ))}
                                                </table>
                                                <hr />
                                                <div className="checkout__order__total">Total <span>{orderData && NumberFormat(orderData.totalPrice, 'IDR ')}</span></div>

                                                {(orderData.payment.status != 'settlement') &&

                                                    <a href={orderData?.link} className="site-btn">
                                                        Pay Now
                                                    </a>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div> : 
                    <div className="checkout__form">
                        <h2><Skeleton/></h2>
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>
                    </div>
                    }
                </div>
            </section>

        </>
    )
}

export default OrderDetail