import { Field, Form, Formik, useField } from 'formik';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LoginAction } from '../../redux/actions/AuthActions';
import { FaCheck, FaFacebookF, FaGoogle } from "react-icons/fa";

const Login = () => {

    const history = useNavigate();
    const dispatch = useDispatch();
    //const authResponse = useSelector(state=>state.userAuth.authResponse);
    const [fields, setState] = useState({
        email: "",
        password: "",
    });
    const handleFieldChange = (e) => {
        setState({
            ...fields,
            [e.target.id]: e.target.value,
        });
    };
    const UserLogin = (e) => {
        e.preventDefault();
        console.log(fields);
        dispatch(LoginAction(fields, history));
    };

    const handleSubmit = (values) => {
        // event.preventDefault();
        const formData = new FormData();

        const loginCredentials = {
            email: values.email,
            password: values.password,
        };

        dispatch(LoginAction(loginCredentials, history));

        // const authenticatedCallback = () => {
        //   let { from } = location.state || { from: { pathname: "/dashboard" } };
        //   history.replace(from);
        // };
        // axios
        //   .post("http://localhost:8000/api/login", loginCredentials)
        //   .then((response) => {
        //     user.authenticated(response.data, authenticatedCallback);
        //   });
    };

    return (
        <div className="content">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 d-flex">
                        <img src={process.env.REACT_APP_STORAGE_URL + 'img/logo.png'} alt="Image" className="img-fluid my-auto" />
                    </div>
                    <div className="col-md-6 contents">
                        <div className="row justify-content-center">
                            <div className="col-md-8">
                                <div className="mb-4">
                                    <h3>Sign In</h3>
                                    <p className="mb-4">Lorem ipsum dolor sit amet elit. Sapiente sit aut eos consectetur adipisicing.</p>
                                </div>
                                <Formik
                                    initialValues={{
                                        email: "",
                                        password: "",
                                    }}
                                    onSubmit={async (values) => {
                                        handleSubmit(values);
                                    }}
                                >
                                    {({
                                        setFieldValue,
                                        setFieldTouched,
                                        values,
                                        errors,
                                        touched,
                                    }) => (
                                        <Form>
                                            <div className={`form-group first ${values.email != '' ? 'field--not-empty' : null}`}>
                                                <label htmlFor="username">Email</label>
                                                <Field
                                                    className="form-control"
                                                    id="email"
                                                    name="email"
                                                    placeholder=""
                                                    type="text"
                                                />
                                                {/* <input type="text" className="form-control" id="username" /> */}
                                            </div>
                                            <div className={`form-group last mb-4 ${values.email != '' ? 'field--not-empty' : null}`}>
                                                <label htmlFor="password">Password</label>
                                                <Field
                                                    className="form-control"
                                                    id="password"
                                                    name="password"
                                                    placeholder=""
                                                    type="password"
                                                />
                                                {/* <input type="password" className="form-control" id="password" /> */}
                                            </div>
                                            <div className="d-flex mb-5 align-items-center">
                                                <label className="control control--checkbox mb-0"><span className="caption">Remember me</span>
                                                    <input type="checkbox" defaultChecked="checked" />
                                                    <div className="control__indicator">
                                                        <FaCheck />
                                                    </div>
                                                </label>
                                                <span className="ml-auto"><a href="#" className="forgot-pass">Forgot Password</a></span>
                                            </div>
                                            <button type="submit" defaultValue="Log In" className="btn btn-block btn-primary">
                                                Login
                                            </button>
                                            <span className="d-block text-left my-4 text-muted">— or login with —</span>
                                            <div className="social-login">
                                                <a href="#" className="facebook mr-3">
                                                    {/* <span className="icon-facebook mr-3" /> */}
                                                    <span><FaFacebookF /></span>
                                                </a>
                                                {/* <a href="#" className="twitter mr-3">
                                            <span className="icon-twitter mr-3" />
                                        </a> */}
                                                <a href="#" className="google mr-3">
                                                    {/* <span className="icon-google mr-3" /> */}
                                                    <span><FaGoogle /></span>
                                                </a>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Login