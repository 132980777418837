import HttpService from "./HttpService";

export const PostCartService = (credentials) => {
    const http = new HttpService();
    let url = "cart";
    const tokenId = "user-token";
    return http.postData(credentials, url, tokenId).then((data) => {
        // console.log(data);
        return data;
    }).catch((error) => {
        return error;
    })
}
export const PlaceOrderService = (data) => {
    const http = new HttpService();
    let url = "placeorder";
    const tokenId = "user-token";
    return http.postData(data, url, tokenId).then((data) => {
        return data;
    }).catch((error) => {
        return error;
    })
}
export const FetchCartService = () => {
    const http = new HttpService();
    let url = "cart";
    const tokenId = "user-token";
    return http.getData(url,tokenId).then((data) => {
        return data;
    }).catch((error) => {
        return error;
    })
}